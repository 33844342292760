<template>
    <div class="loading-container">
      <div class="spinner"></div>
      <span v-if="$attrs.message">
          <p v-html="$attrs.message"></p>
      </span>
      <span v-else>
        <p>Loading, please wait...</p>
      </span>
    </div>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #ffbf3f;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  